import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import Head from 'next/head'

import { Button, Input, Card, TextLink, ErrorMessage } from 'ds/components'

import { sessionService } from 'lib/services/sessionService'
import { gtag } from 'lib/utils/gtag'
import { withSimpleLayout } from 'lib/hoc/withSimpleLayout'
import { useForm } from 'lib/hooks/useForm'
import { mutationOnSuccess } from 'lib/utils/mutationUtils'

function Login() {
    const router = useRouter()
    const queryClient = useQueryClient()

    useEffect(() => {
        // Remove all queries when mounted
        queryClient.removeQueries()
    }, [queryClient])

    const {
        formData,
        formContext,
        formError,
        handleBlur,
        handleChange,
        setFormError,
        getFormError,
    } = useForm(
        { email: '', password: '' },
        { allRequired: true, email: { type: 'email' } }
    )

    const { isPending, mutate } = useMutation({
        mutationFn: sessionService.login,
        onError: () => setFormError('Algo salió mal.'),
        onSuccess: mutationOnSuccess({
            onError: () => setFormError('Email y/o contraseña inválidos.'),

            onSuccess: () => {
                gtag('event', 'login', { method: 'email' })
                router.push('/dashboard')
            },
        }),
    })

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (getFormError()) return false
        mutate(formData)
    }

    return (
        <Card>
            <Head>
                <title>Iniciar Sesión - Savio</title>
            </Head>
            <div>
                <form
                    name="login"
                    onSubmit={(e) => handleSubmit(e)}
                    className="stacked-md"
                >
                    <Input
                        label="Email"
                        className="stacked-md"
                        formContext={formContext.email}
                        value={formData.email}
                        onChange={(value) => handleChange('email', value)}
                        onBlur={() => handleBlur('email')}
                    />
                    <Input
                        label="Contraseña"
                        className="stacked-lg"
                        formContext={formContext.password}
                        value={formData.password}
                        type="password"
                        onChange={(value) => handleChange('password', value)}
                        onBlur={() => handleBlur('password')}
                    />
                    <Button
                        fullWidth
                        className="stacked-sm"
                        isLoading={isPending ? 'Procesando...' : undefined}
                        type="submit"
                    >
                        Ingresar
                    </Button>
                    <ErrorMessage>{formError}</ErrorMessage>
                </form>
                <div className="row">
                    <TextLink
                        className="col-6"
                        href="/forgot-password"
                        variant="secondary"
                    >
                        ¿Olvidaste tu contraseña?
                    </TextLink>
                    <TextLink
                        className="col-6 right"
                        href="https://forms.savio.mx/demo"
                        variant="secondary"
                    >
                        Regístrate
                    </TextLink>
                </div>
            </div>
        </Card>
    )
}

export default withSimpleLayout(Login)
