import { Logo } from 'ds/components/Logo'
import { ComponentType } from 'react'

export function withSimpleLayout(WrappedComponent: ComponentType) {
    return function WithSimpleLayout() {
        return (
            <div className="container-sm">
                <div style={{ paddingTop: '100px', paddingBottom: '32px' }}>
                    <a href="https://savio.mx">
                        <Logo />
                    </a>
                </div>
                <WrappedComponent />
            </div>
        )
    }
}
