import { isEmail } from 'react-multi-email'
import { isValidDateString } from './dateStringUtils'

const minLength = 6
export const passwordError = (value: string | null | undefined) => {
    if (!value?.trim()) return 'Contraseña es requerida'

    if (value.length < minLength) {
        return `Contraseña debe tener al menos ${minLength} caracteres`
    }

    if (!/[a-zA-Z]/.test(value)) return 'Contraseña debe contener al menos una letra.'
    if (!/\d/.test(value)) return 'Contraseña debe contener al menos un número.'
}

export const emailError = (value: string | null | undefined) => {
    if (!value?.trim()) return 'Correo electrónico es requerido'

    return !isEmail(value) ? 'Correo electrónico inválido' : undefined
}

export const dateStringError = (value: string | null | undefined) =>
    isValidDateString(value) ? undefined : 'Fecha inválida'

export const isNestedObject = (obj: any): boolean => {
    // If it's not an object or is null, it's not a nested object
    if (!obj || typeof obj !== 'object') return false
    // If it has error or required properties, it's a form context object, not a nested object
    if ('error' in obj || 'required' in obj) return false
    // If it's an array or Date, it's not a nested object
    if (Array.isArray(obj) || obj instanceof Date) return false

    return true
}
